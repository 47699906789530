// extracted by mini-css-extract-plugin
export var bgimage = "topiccallout-module--bgimage--0df4e";
export var btn = "topiccallout-module--btn--8c09a";
export var btnblock = "topiccallout-module--btnblock--be80a";
export var card = "topiccallout-module--card--cf71e";
export var card__content = "topiccallout-module--card__content--ee058";
export var card__image = "topiccallout-module--card__image--e8ddb";
export var card__text = "topiccallout-module--card__text--857b8";
export var card__text__height = "topiccallout-module--card__text__height--78a73";
export var card__title = "topiccallout-module--card__title--326ac";
export var cardbtn = "topiccallout-module--cardbtn--27e96";
export var cardimage = "topiccallout-module--cardimage--d4251";
export var cards = "topiccallout-module--cards--d2ccb";
export var cards__item = "topiccallout-module--cards__item--41429";
export var cardtn = "topiccallout-module--cardtn--3c40b";
export var centeredGallary = "topiccallout-module--centeredGallary--0279a";
export var contentHolder = "topiccallout-module--contentHolder--07b17";
export var desktop = "topiccallout-module--desktop--0eae1";
export var dots = "topiccallout-module--dots--72a60";
export var flex = "topiccallout-module--flex--8f01f";
export var gallery = "topiccallout-module--gallery--d24a2";
export var icon = "topiccallout-module--icon--4c21a";
export var information = "topiccallout-module--information--ada13";
export var nextArrowButton = "topiccallout-module--nextArrowButton--b5d4d";
export var officeCard = "topiccallout-module--officeCard--a4d56";
export var prevArrowButton = "topiccallout-module--prevArrowButton--ff65b";
export var producttitle = "topiccallout-module--producttitle--86d3e";
export var row = "topiccallout-module--row--292a4";
export var stackspecificstyles = "topiccallout-module--stackspecificstyles--4e196";
export var title = "topiccallout-module--title--b102d";
export var view = "topiccallout-module--view--c1b79";
export var viewsHolder = "topiccallout-module--viewsHolder--dbb86";
export var viewtitle = "topiccallout-module--viewtitle--4e0dc";
export var wrapper = "topiccallout-module--wrapper--28b71";